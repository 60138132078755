/**
 * @file ttl.js
 * 
 * The functions in this file are used to handle the time to live of the cache.
 */


// type TTLItem = {
//     value: any,
//     ttl: number,
//     callback: string,
//     last_updated: number,
// }

const CryptoJS = require('crypto-js')
const SecretKey = 'secretkey'

const encrypt = (data) => {
    return CryptoJS.AES.encrypt(data, SecretKey).toString()
}

const decrypt = (data) => {
    return CryptoJS.AES.decrypt(data, SecretKey).toString(CryptoJS.enc.Utf8)
}

/**
 * Throws an error if the item is not a valid TTLItem.
 * 
 * @param {*} item - The item to validate
 */
function validateTTLItem(item) {
    if (typeof item !== 'object' ||
        (item.ttl && typeof item.ttl !== 'number') ||
        (item.value === undefined) || 
        (item.callback && typeof item.callback !== 'string') ) {
        throw new Error('Invalid item')
    }
}

/**
 * Fetches the item from the local storage.
 * Returns the value if the item is valid and the ttl has not expired.
 * If the ttl has expired, the callback is called and the value is updated and returned.
 * 
 * @param {object} app - The app object that contains the callback function
 * @param {string} key - The key of the item to get
 * @param {boolean} session - Uses the session storage instead of the local storage
 * @returns 
 */
async function get(app, key, session = false) {
    const storage = session ? sessionStorage : localStorage

    let item = JSON.parse(
        await decrypt(
            storage.getItem(key)
        )
    )

    validateTTLItem(item)

    if (item) {

        // if the ttl is -1, never update
        if (item.ttl === -1) return item.value
        
        // if the ttl is 0, always update
        const update_time = Date.now()
        if (item.ttl !== undefined && item.ttl + item.last_updated < update_time) {
            // run the update function
            if (item.callback) {
                const data = await app[item.callback]()
                if (data) {
                    // update value
                    item.value = data
                    // update ttl
                    item.last_updated = update_time
                    // save new item value
                    storage.setItem(
                        key,
                        encrypt(JSON.stringify(item))
                    )

                    return data
                }
            }
        }
        return item.value
    }
    return null
}

/**
 * Encrypts and sets the item in the local storage.
 * 
 * @param {object} app - The app object that contains the callback function
 * @param {string} key - The key of the item to set
 * @param {*} value - The value of the item
 * @param {number} ttl - The time to live of the item, use -1 to never upodate and 0 to always update
 * @param {function} callback - The callback function to run when the ttl has expired
 * @param {boolean} session - Uses the session storage instead of the local storage
 */
async function set(app, key, value, ttl, callback, session = false) {
    const storage = session ? sessionStorage : localStorage

    // we need to add !== undefined because 0 is a valid value
    if (ttl !== undefined && typeof ttl !== 'number') {
        throw new Error('Invalid ttl')
    }
    if (callback && typeof callback !== 'function' && app[callback] === undefined) {
        throw new Error('Invalid callback')
    }

    const item = {
        value: value,
        ttl: ttl,
        callback: callback.name,
        last_updated: Date.now(),
    }

    // debug
    // console.log('item', item);
    // console.log('key', key);

    // encrypt and set the item
    storage.setItem(
        key,
        encrypt(JSON.stringify(item)),
    )
}

/**
 * Updates the item in the local storage by calling the item's callback function.
 * 
 * @param {object} app - The app object that contains the callback function
 * @param {string} key - The key of the item to update
 * @param {boolean} session - Uses the session storage instead of the local storage
 */
async function update(app, key, session = false) {
    const storage = session ? sessionStorage : localStorage
    
    let item = JSON.parse(
        decrypt(
            storage.getItem(key)
        )
    )

    validateTTLItem(item)

    if (item) {
        if (item.callback) {
            const data = await app[item.callback]();
            if (data) {
                item.value = data
                item.last_updated = Date.now()
                storage.setItem(
                    key,
                    encrypt(JSON.stringify(item))
                )
            }
        }
    }
}

/**
 * Checks if the item is in the local storage.
 * 
 * @param {string} key - The key of the item to check
 * @param {boolean} session - Uses the session storage instead of the local storage
 * @returns 
 */
async function has(key, session = false) {
    const storage = session ? sessionStorage : localStorage
    return storage.getItem(key) !== null
}

/**
 * Removes the item from the local storage.
 * 
 * @param {string} key - The key of the item to remove
 * @param {boolean} session - Uses the session storage instead of the local storage
 */
async function remove(key, session = false) {
    const storage = session ? sessionStorage : localStorage
    storage.removeItem(key)
}

export const TTLStorage = {
    get,
    set,
    update,
    has,
    remove,
}