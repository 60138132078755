window.FeedbackCards = () => {
  return {
    messages: [
      {
        message: 'Still using Excel? What are you, a financial advisor in the 80s? HeyFIRE rocks!',
        user: {
          name: 'Jane',
          job: 'Entepreneur'
        }
      },
      {
        message: 'You need some review of your portfolio? HeyFire makes it **ridiculously easy** to build, share and track portfolios',
        user: {
          name: 'Lucy',
          job: 'Investor'
        }
      },
      {
        message: 'OMG. Cryptocurrencies, stocks, NFTs all in all place? Financial orgasm.',
        user: {
          name: 'Ives',
          job: 'Skater'
        }
      }
    ]
  }
}