const firebaseConfig = {
    apiKey: "AIzaSyA0H2A6mRcblks28ePp-Pv08ld8SlEUGtI",
    authDomain: "heyfire.co",
    projectId: "heyfire",
    storageBucket: "heyfire.appspot.com",
    messagingSenderId: "905795439930",
    appId: "1:905795439930:web:9603de2b1a5286bcba1403",
    measurementId: "G-PSEZ0WNHL9"
};

export default firebaseConfig;